<template>
  <div class="tabs-slider">
    <div class="mb-8">
      <div class="tabs-slider__title mr-8 mb-0">Материалы по теме</div>
      <div class="tabs-slider__tab-row">
        <div
          class="tabs-slider__tab"
          v-for="(tab, ind) in tabsList"
          :key="ind"
          @click="current_tab = tab.tab_name"
          :class="{ 'tabs-slider__tab_active': current_tab === tab.tab_name }"
        >
          {{ tab.tab_name }}
        </div>
      </div>
    </div>
    <div class="tabs-slider__tab-content">
      <VueSlickCarousel
        v-if="filteredTabMaterials.length"
        v-bind="settings"
        ref="tabsSlider"
        @init="onInitCarousel"
        @reInit="onInitCarousel"
      >
        <Material
          v-for="object in filteredTabMaterials"
          :item="object.object"
          :key="object.object_id"
          :page="page || object.page"
          @clickMaterials="clickMaterials"
        />
      </VueSlickCarousel>
      <div class="tabs-slider__arrows" v-if="showArrows">
        <div class="tabs-slider__arrow mr-3" @click="$refs.tabsSlider.prev()">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.25 4.50012L3.75 12.0001L11.25 19.5001"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
            <path
              d="M5.25 12.0001L20.25 12.0001"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </div>
        <div class="tabs-slider__arrow ml-3" @click="$refs.tabsSlider.next()">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.75 4.50012L20.25 12.0001L12.75 19.5001"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
            <path
              d="M18.75 12.0001L3.75 12.0001"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import Material from "./Material.vue";
export default {
  name: "TabSlider",
  props: {
    tabs: Array,
    page: String,
    video: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VueSlickCarousel,
    Material,
  },
  data: () => ({
    showArrows: true,
    current_tab: null,
    settings: {
      infinite: false,
      slidesToShow: 3,
      arrows: false,
      dots: false,
      responsive: [
        {
          breakpoint: 1220,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    },
  }),
  computed: {
    ...mapGetters(["MedicationDetail"]),
    filteredTabMaterials() {
      return this.tabs.filter((el) => el.tab_name === this.current_tab);
    },
    tabsList() {
      let arr = this.tabs.map((el) => ({tab_name: el.tab_name}))
      return [...new Map(arr.map((item) => [item["tab_name"], item])).values()];
    }
    // filterTabsByName() {
    //   let map = new Map();
    //   let tabName = this.tabs.filter((el) => {
    //     if (map.get(el.tab_name)) {
    //       return console.log(false)
    //     }
    //     map.set(el.tab_name, el);
    //     return console.log(true)
    //   });
    //   for (let tab of tabName) {
    //     this.tabs.filter((el) => el.id === this.current_tab);
    //   }
    // }
  },
  methods: {
    onInitCarousel() {
      this.showArrows =
        this.$refs.tabsSlider?.settings.slidesToShow <
        this.filteredTabMaterials.length;
    },
    clickMaterials(name) {
      this.$emit("clickMaterials", name);
    },
  },
  mounted() {
    this.current_tab = this.tabs[0].tab_name;
  },
};
</script>

<style lang="scss" scoped>
.tabs-slider {
  margin: 40px 0;
  @media screen and (max-width: 1220px) {
    // padding-top: 32px;
    // margin-top: 32px;
    // padding-bottom: 120px;
    // margin-bottom: -120px;
  }

  &__title {
    margin-bottom: 32px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #3c4242;

    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }

    &_highlight {
      text-decoration: underline;
      color: #830051;
      transition: 0.3s;
    }
  }

  &__tab-row {
    margin-top: 16px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    overflow: auto;
  }

  &__tab {
    height: fit-content;
    // width: 100%;
    min-width: fit-content;
    display: flex;
    cursor: pointer;
    padding: 4px 12px;
    border: 1px solid #d8dada;
    border-radius: 99px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    &_active {
      border-color: #830051;
      color: #830051;
    }
  }

  .material {
    height: 100%;
  }

  &__arrows {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__arrow {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #d8dada;
    cursor: pointer;
    color: #8b8e8e;
    transition: 0.3s;

    &:hover {
      color: var(--color-hover);
      background-color: #ebefee;
    }
  }
}
</style>

<style lang="scss">
.tabs-slider {
  .slick-slider {
    margin: 0 -16px;
  }

  .slick-list,
  .slick-track {
    display: flex;
  }

  .slick-slide {
    padding: 0 16px;
    height: auto;
  }

  .slick-slide > div {
    height: 100%;
  }
}
</style>
